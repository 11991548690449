@font-face {
  font-family: "questrialregular";
  src: url("../fonts/questrial-regular-webfont.woff2") format("woff2"),
    url("../fonts/questrial-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserratbold";
  src: url("../fonts/montserrat-bold-webfont.woff2") format("woff2"),
    url("../fonts/montserrat-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
#root {
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  background: transparent;
  font-weight: normal;
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
}
ol,
ul {
  list-style: none;
}
blockquote {
  quotes: none;
}
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}
del {
  text-decoration: line-through;
}
header,
section,
article,
aside,
nav,
footer,
address {
  display: block;
}

body {
  font-family: "nunitoregular", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #555;
  min-width: 320px;
  overflow-x: hidden;
}
input,
textarea,
select {
  font-family: Arial, Helvetica, sans-serif;
}
a {
  color: #555;
}
a:hover,
.submit:hover {
  filter: alpha(opacity = 85);
  -moz-opacity: 0.85;
  -khtml-opacity: 0.85;
  opacity: 0.85;
}
.thumb {
  display: block;
}
.thumb img {
  display: block;
  width: 100%;
}
p {
  line-height: 1.6em;
}
.hidden {
  display: none;
}

br.clear {
  clear: both;
  line-height: 0;
}
.wrapper {
  width: 90%;
  margin: 0 auto;
  max-width: 1389px;
}
.header {
}
.heading {
  display: flex;
  margin-bottom: 30px;
}
.h1 {
}
.header h1 a {
}
.header h1 a img {
  width: 30%;
  margin-top: 40px;
}
.header ul {
  display: flex;
  width: 85%;
  justify-content: flex-end;
  line-height: 144px;
  font-size: 19px;
  font-family: "questrialregular";
}
.header ul li {
}
.header ul li a {
  display: inline-block;
  color: #fff;
  border-radius: 30px;
  padding: 15px 56px;
  margin-right: 28px;
  font-weight: bold;
}
.home:active {
  background-color: #3f3d56;
  padding: 14px 31px;
  border-radius: 20px;
  color: white;
}
.contact:active {
  background-color: #3f3d56;
  padding: 14px 31px;
  border-radius: 20px;
  color: white;
}
.feature:active {
  background-color: #3f3d56;
  padding: 14px 31px;
  border-radius: 20px;
  color: white;
}
.home:hover {
  background-color: #3f3d56;
  padding: 14px 31px;
  border-radius: 20px;
  color: white;
}
.contact:hover {
  background-color: #3f3d56;
  padding: 14px 31px;
  border-radius: 20px;
  color: white;
}
.feature:hover {
  background-color: #3f3d56;
  padding: 14px 31px;
  border-radius: 20px;
  color: white;
}
.feature {
  margin: 0 30px;
}
.spotlight {
}
.spotlight h1 {
  font-size: 57px;
  font-family: "montserratbold";
  color: #363636;
}
.left {
}
.spotlight {
  margin-top: 110px;
}
.spotlight p {
  font-size: 25px;
  margin: 30px 9px;
  font-family: "questrialregular";
  visibility: visible;
  animation-duration: 1.5s;
  animation-name: slideInRight;
}
.flexcontainer {
  display: flex;
  align-items: stretch;
}
.flexcontainer a {
}
.img1 {
  width: 12%;
  margin-bottom: 13px;
}
.img2 {
  width: 14%;
  margin-top: 33px;
}
.scoial {
}
.icon {
  width: 63px;
  margin-top: 20px;
  margin-left: 22px;
  border-radius: 86px;
}
.icon a {
  margin: 0 10px;
}
.icon a img {
  margin: 57px 11px;
  background-color: #38364d;
  color: #fff;
  width: 40px;
  border-radius: 16px;
  padding: 10px;
}
.right {
  position: relative;
  z-index: -1;
}
.right img {
  width: 135%;
  margin-top: -46%;
  margin-left: -166px;
}
.about {
  display: flex;
  padding: 0px 140px;
}
.heading1 {
  font-size: 55px;
  color: black;
  font-family: "montserratbold";
  visibility: visible;
  animation-duration: 1.5s;
  animation-name: slideInLeft;
}
.detail {
  margin: -49px;
  margin-top: 12px;
  margin-left: -120px;
}
.detail p {
  font-size: 17px;
  line-height: 32px;
  color: black;
  margin-top: 7%;
  margin-left: -7px;
  font-family: "questrialregular";
  visibility: visible;
  animation-duration: 1.5s;
  animation-name: slideInRight;
}
.right {
  flex: 1;
}
.right1 img {
  height: 109%;
  display: inline-block;
}
.user {
  margin-left: -14px;
}
.use1 {
  margin-left: -16px;
}
.right1 {
  margin-right: -4px;
}
.room {
  text-align: center;
  font-size: 50px;
  font-family: "montserratbold";
  color: #000000;
  margin-left: 6%;
  visibility: visible;
  animation-duration: 1.5s;
  animation-name: slideInLeft;
  visibility: visible;
  animation-duration: 1.5s;
  animation-name: slideInLeft;
  padding: 44px;
}
.pixel img {
  width: 129px;
  border-radius: 54%;
  margin-top: 53px;
  height: 117px;
}
.hander {
  display: flex;
  height: 244px;
  background-color: #e8e8e8;
  border-radius: 64px;
}
.writer {
}
.writer p {
  margin-top: 72px;
  font-size: 20px;
  line-height: 32px;
  color: black;
  font-family: "questrialregular";
  margin-right: 187px;
  transform: scale(1);
  animation: zoom 0.2s ease;
}
@keyframes zoom {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.pixel {
  padding: 0px 77px;
}
.centre {
  margin-left: 375px;
}
.pit {
  margin-right: 39px;
}
.pit img {
  width: 124px;
  border-radius: 54%;
  height: 117px;
}
.backend {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin: 104px;
}
.review {
  margin-top: 66px;
}
.review1 {
  margin-left: 107%;
  width: 34%;
  margin-top: -156%;
}
.footer {
  width: 100%;
}
.footer .wrapper {
}
.container-box {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.contact h2 {
  font-size: 28px;
  color: #000;
  width: 30%;
}
.edit {
  font-size: 20px;
}
.contact1 {
  width: 50%;
  padding-top: 147px;
  padding-left: 10%;
}
.contact1 h2 {
  color: black;
  font-family: "montserratbold";
  font-size: 48px;
  margin-top: -15px;
  margin: -50px -52px;
  visibility: visible;
  animation-duration: 1.5s;
  animation-name: slideInLeft;
}
.contact1 p {
  font-size: 28px;
  margin-top: 20px;
  color: black;
  font-family: "questrialregular";
  visibility: visible;
  animation-duration: 1.5s;
  animation-name: slideInRight;
}
.studio {
  display: flex;
  align-items: center;
}
.icons {
  display: flex;
}
.icon1 {
  width: 3%;
  margin: -93px 9px;
}
.icon1 a {
}
.icon1 a img {
  width: 40px;
  border-radius: 16px;
  margin: 159px 73px;
  background-color: #38364d;
  color: #fff;
  padding: 10px;
}
.getintouch {
  background-color: #ff761b;
  width: 50%;
  padding: 83px 250px 83px 118px;
}
.getintouch h2 {
  font-size: 39px;
  color: #fff;
  font-family: "montserratbold";
  visibility: visible;
  animation-duration: 1.5s;
  animation-name: slideInLeft;
}
.getintouch form {
}
.getintouch form input {
  border: none;
  padding: 14px 20px;
  margin: 20px 0;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
}
.getintouch form textarea {
  border: 1px solid #eae8e8;
  padding: 23px 20px;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
}
.triangle {
  width: 0;
  height: 0;
  border-left: 69px solid transparent;
  border-right: 2px solid transparent;
  border-top: 118px solid #e8e8e8;
  position: absolute;
  top: -116px;
  display: none;
}
.last {
  margin-left: 240px;
  height: 109%;
}
.getintouch button {
  display: inline-block;
  padding: 11px 288.1px;
  font-size: 20px;
  margin-top: 15px;
  background-color: #38364d;
  color: white;
  width: 100%;
}
.qwer {
  display: flex;
}
.hello {
  margin-left: 4%;
  width: 48%;
}
.bottom {
  background: #38364d;
  padding: 20px 0;
  text-align: center;
  color: #fff;
}
.jkl {
  color: white;
  margin-left: 5px;
}
.contact1 a {
  font-size: 20px;
  color: black;
  font-family: "questrialregular";
  visibility: visible;
  animation-duration: 1.5s;
  animation-name: slideInRight;
  text-decoration: none;
}
.parent_2 {
  font-size: 19px;
  font-family: "questrialregular";
  visibility: visible;
  animation-duration: 1.5s;
  animation-name: slideInRight;
  margin-top: 12px;
}
.boxing_1 {
  display: flex;
}
.parent_1 img {
  margin-left: -52px;
  margin-top: 13px;
}
.informatin h2 {
  margin-top: -101px;
  padding: 43px;
  margin-left: -9%;
  color: black;
  font-family: "montserratbold";
  visibility: visible;
  animation-duration: 1.5s;
  animation-name: slideInLeft;
}
.getintouch form {
  width: 100%;
}
.hype {
  width: 48%;
}
.iconic {
  margin-top: 15%;
}
@media (max-width: 1024px) {
  .right img {
    width: 135%;
    margin-top: -63%;
    margin-left: -166px;
  }
  .header h1 a img {
    width: 36%;
    margin-top: 40px;
  }
  .spotlight h1 {
    font-size: 57px;
    font-family: "montserratbold";
    color: #363636;
  }
  .spotlight p {
    font-size: 23px;
    margin: 30px 9px;
    font-family: "questrialregular";
    visibility: visible;
    animation-duration: 1.5s;
    animation-name: slideInRight;
  }
  .img2 {
    width: 15%;
    margin-top: 38px;
  }
  .img1 {
    width: 13%;
    margin-bottom: 9px;
  }

  .detail p {
    font-size: 17px;
    line-height: 32px;
    color: black;
    margin-top: 7%;
    margin-left: -44px;
    font-family: "questrialregular";
    visibility: visible;
    animation-duration: 1.5s;
    animation-name: slideInRight;
  }
  .backend {
    display: flex;
    padding: 110px 113px;
    position: relative;
  }

  .bottom {
    background: #38364d;
    padding: 20px 0;
    text-align: center;
    color: #fff;
    margin: 0 -211px;
  }
  .icon a img {
    margin: 56px -61px;
    background-color: #38364d;
    color: #fff;
    width: 18px;
    border-radius: 16px;
    padding: 10px;
  }
  .hander {
    display: flex;
    height: 244px;
    width: 919px;
    background-color: #e8e8e8;
    border-radius: 64px;
  }
  .pixel {
    padding: 33px 77px;
  }
  .pixel img {
    width: 107px;
    border-radius: 54%;
    margin-top: 46px;
    height: 111px;
  }
  .backend {
    display: flex;
    padding: 110px 114px;
    position: relative;
  }
  .pit img {
    width: 94px;
    border-radius: 54%;
    height: 101px;
  }
  .writer p {
    margin-top: 39px;
    font-size: 20px;
    line-height: 32px;
    color: black;
    font-family: "questrialregular";
    margin-right: 187px;
    transform: scale(1);
    animation: zoom 0.2s ease;
  }
}
@media all and (max-width: 980px) {
  .header ul {
    display: flex;
    width: 85%;
    justify-content: flex-end;
    line-height: 144px;
    font-size: 19px;
    font-family: "questrialregular";
    margin-right: 176px;
  }
  .contact1 h2 {
    color: black;
    font-family: "montserratbold";
    font-size: 48px;
    margin-top: -15px;
    /* margin: -50px -52px; */
    visibility: visible;
    animation-duration: 1.5s;
    animation-name: slideInLeft;
  }
  .footer {
    width: 100%;
  }
  .spotlight p {
    font-size: 18px;
    margin: 30px 9px;
    font-family: "questrialregular";
    visibility: visible;
    animation-duration: 1.5s;
    animation-name: slideInRight;
  }
  .wrapper {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
  }
  .right1 img {
    height: 109%;
    display: inline-block;
    display: none;
  }
  .last {
    margin-left: 240px;
    height: 109%;
    display: none;
  }
  .pit {
    margin-right: 44px;
  }
  .pixel {
    padding: 0px 15px;
  }
  .icon1 {
    width: 3%;
    margin: -93px 22px;
  }
  .icon a img {
    margin: 56px -61px;
    background-color: #38364d;
    color: #fff;
    width: 35px;
    border-radius: 16px;
    padding: 6px;
  }
  .img2 {
    width: 16%;
    margin-top: 33px;
  }
  .backend {
    display: flex;
    margin: 0;
  }
  .information {
  }
  .img1 {
    width: 14%;
    margin-bottom: 10px;
  }
  .about {
    margin: 0;
    padding: 0;
  }
  .heading1 {
    font-size: 42px;
    color: black;
    font-family: "montserratbold";
    /* margin: 34px 44px; */
    text-align: center;
    margin: 0;
    margin-bottom: 15px;
  }
  .right1 {
    margin: 0;
  }
  .detail {
    margin: 0;
    padding: 0;
    width: 100%;
    padding: 20px 0;
  }
  .detail p {
    font-size: 17px;
    line-height: 32px;
    color: black;
    /* margin-top: 72%; */
    margin-left: 81px;
    font-family: "questrialregular";
    visibility: visible;
    animation-duration: 1.5s;
    animation-name: slideInRight;
    /* margin: 73px; */
    text-align: center;
  }
  .right img {
    width: 151%;
    margin-top: -76%;
    margin-left: -166px;
  }
  .container-box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .getintouch {
    background-color: #ff761b;
    width: 100%;
    padding: 86px 271px 29px 175px;
  }
  .contact1 {
    width: 100%;
    padding-top: 147px;
  }
  .icon1 a img {
    width: 32px;
    border-radius: 16px;
    margin: 159px 73px;
    background-color: #38364d;
    color: #fff;
    padding: 6px;
  }
  .use1 {
    margin: 0%;
  }
  .user {
  }
  .triangle {
    top: -11px;
  }
}
@media all and (max-width: 640px) {
  .wrapper {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
  }
  .header ul {
    display: flex;
    width: 85%;
    justify-content: flex-end;
    line-height: 144px;
    font-size: 16px;
    font-family: "questrialregular";
    margin: 1px 119px;
  }
  .right1 img {
    height: 100%;
    display: inline-block;
    display: none;
  }
  .last {
    margin-left: 240px;
    height: 100%;
    display: none;
  }
  .header h1 a img {
    width: 100%;
    margin-top: 40px;
  }
  .bottom {
    background: #38364d;
    text-align: center;
    color: #fff;
  }
  .img2 {
    width: 17%;
    margin-top: 33px;
  }
  .container-box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .getintouch {
    background-color: #ff761b;
    width: 100%;
    padding: 39px 41px 39px 51px;
  }
  .getintouch button {
    display: inline-block;
    padding: 15px 185.1px;
    font-size: 16px;
    margin-top: 16px;
    background-color: #38364d;
    color: white;
  }
  .pit {
    margin-right: 12px;
  }
  .pit img {
    width: 88px;
    border-radius: 54%;
    height: 84px;
  }
  .user {
  }
  .detail {
    /* margin: 71px -41px; */
    margin-top: 67px;
  }
  .hander {
    display: flex;
    height: 203px;
    background-color: #e8e8e8;
    border-radius: 64px;
  }
  .writer p {
    margin-top: 32px;
    font-size: 14px;
    line-height: 32px;
    color: black;
    font-family: "questrialregular";
    margin-right: 187px;
    transform: scale(1);
    animation: zoom 0.2s ease;
  }

  .right img {
    width: 232%;
    margin-top: -135%;
    margin-left: -380px;
  }
  .contact1 {
    width: 100%;
    padding-top: 147px;
    padding-left: 38%;
  }
  .detail p {
    font-size: 17px;
    line-height: 32px;
    color: black;
    /* margin-top: 72%; */
    /* margin-left: 81px; */
    font-family: "questrialregular";
    visibility: visible;
    animation-duration: 1.5s;
    animation-name: slideInRight;
    /* margin: 73px; */
  }
  .about {
    display: flex;
    padding: 2px;
  }
  .backend {
    display: flex;
    padding: 110px 45px;
  }
}
@media (max-width: 480px) {
  .about {
    display: flex;
    /* padding: 1px; */
  }
  .right img {
    width: 285%;
    margin-top: -152%;
    margin-left: -489px;
  }
  .icon1 a img {
    width: 32px;
    border-radius: 16px;
    margin: 159px 6px;
    background-color: #38364d;
    color: #fff;
    padding: 6px;
  }
  .right1 img {
    height: 109%;
    display: inline-block;
    display: none;
  }
  .last {
    margin-left: 240px;
    height: 109%;
    display: none;
  }
  .header ul {
    display: flex;
    width: 79%;
    justify-content: flex-end;
    line-height: 144px;
    font-size: 12px;
    font-family: "questrialregular";
    margin: 1px 13px;
  }
  .header h1 a img {
    width: 52%;
    margin-top: 40px;
  }
  .img2 {
    width: 20%;
    margin-top: 33px;
  }
  .img1 {
    width: 17%;
    margin-bottom: 7px;
  }
  .heading {
    display: flex;
    margin-bottom: -36px;
  }
  .detail p {
    font-size: 17px;
    line-height: 32px;
    color: black;
    margin-top: 7%;
    /* margin-left: 81px; */
    font-family: "questrialregular";
    visibility: visible;
    animation-duration: 1.5s;
    animation-name: slideInRight;
    margin: 1px;
    /* margin-right: 31px; */
  }
  .heading1 {
    font-size: 42px;
    color: black;
    font-family: "montserratbold";
    margin-left: 18px;
  }
  .pit img {
    width: 58px;
    border-radius: 54%;
    height: 67px;
  }
  .backend {
    display: flex;
    padding: 110px 54px;
  }
  .triangle {
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 2px solid transparent;
    border-top: 118px solid #e8e8e8;
    position: absolute;
    top: -6px;
  }
  .pixel img {
    width: 64px;
    border-radius: 54%;
    height: 67px;
  }
  .hander {
    display: flex;
    height: 208px;
    background-color: #e8e8e8;
    border-radius: 64px;
    width: 134%;
  }
  .spotlight p {
    font-size: 16px;
    margin: 30px 9px;
    font-family: "questrialregular";
    visibility: visible;
    animation-duration: 1.5s;
    animation-name: slideInRight;
    color: black;
  }
  .container-box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .getintouch {
    background-color: #ff761b;
    width: 100%;
    padding: 6px 9px 17px 34px;
  }
  .getintouch button {
    display: inline-block;
    padding: 14px 250.1px;
    font-size: 16px;
    margin-top: 16px;
    background-color: #38364d;
    color: white;
  }
  .contact1 {
    width: 100%;
    /* padding-top: 147px; */
    /* padding-left: 33%; */
    margin: 86px;
    padding: 0;
  }
  .bottom {
    background: #38364d;
    text-align: center;
    color: #fff;
  }
  .spotlight h1 {
    font-size: 43px;
    font-family: "montserratbold";
    color: #363636;
  }
}
@media (max-width: 360px) {
  .heading {
    display: flex;
    margin-bottom: -124px;
  }
  .header h1 a img {
    width: 52%;
    margin-top: 40px;
  }
  .right1 img {
    height: 109%;
    display: inline-block;
    display: none;
  }
  .last {
    margin-left: 240px;
    height: 109%;
    display: none;
  }
  .header ul {
    display: flex;
    width: 104%;
    justify-content: flex-end;
    line-height: 144px;
    font-size: 7px;
    font-family: "questrialregular";
    margin: 1px 3px;
  }
  .spotlight h1 {
    font-size: 30px;
    font-family: "montserratbold";
    color: #363636;
  }

  .spotlight p {
    font-size: 14px;
    margin: 30px 9px;
    font-family: "questrialregular";
    visibility: visible;
    animation-duration: 1.5s;
    animation-name: slideInRight;
    color: black;
  }
  .right img {
    width: 298%;
    margin-top: -215%;
    margin-left: -404px;
  }
  .img1 {
    width: 21%;
    margin-bottom: 7px;
  }
  .img2 {
    width: 25%;
    margin-top: 33px;
  }
  .icon1 a img {
    width: 33px;
    border-radius: 24px;
    margin: 148px 1px;
    background-color: #38364d;
    color: #fff;
    padding: 9px;
  }
  .detail p {
    font-size: 17px;
    line-height: 32px;
    color: black;
    margin-top: 7%;
    /* margin-left: 81px; */
    font-family: "questrialregular";
    visibility: visible;
    animation-duration: 1.5s;
    animation-name: slideInRight;
    /* margin: 1px -80px; */
    /* margin-right: 31px; */
  }
  .about {
    display: flex;
    /* padding: 0px 133px; */
  }
  .bottom {
    background: #38364d;
    text-align: center;
    color: #fff;
    /* width: 100%; */
  }
  .backend {
    display: flex;
    padding: 110px 37px;
  }
  .parent_2 {
    font-size: 14px;
    font-family: "questrialregular";
    visibility: visible;
    animation-duration: 1.5s;
    animation-name: slideInRight;
    margin-top: 12px;
  }
  .contact1 {
    width: 81%;
    padding-top: 71px;
    /* padding-left: 34%; */
  }
  .getintouch button {
    display: inline-block;
    padding: 14px 148.1px;
    font-size: 16px;
    margin-top: 16px;
    background-color: #38364d;
    color: white;
  }
  .getintouch {
    background-color: #ff761b;
    width: 100%;
    padding: 34px 3px 23px 13px; /* display: none; */
  }
  .pixel {
    padding: 11px 0;
  }
  .pit img {
    width: 43px;
    border-radius: 54%;
    height: 48px;
  }
  .hander {
    display: flex;
    height: 208px;
    background-color: #e8e8e8;
    border-radius: 64px;
    width: 151%;
  }
}
@media (max-width: 320px) {
  .header h1 a img {
    width: 43%;
    margin-top: 40px;
  }
  .right1 img {
    height: 109%;
    display: inline-block;
    display: none;
  }
  .last {
    margin-left: 240px;
    height: 109%;
    display: none;
  }

  .header ul {
    display: flex;
    width: 13%;
    justify-content: flex-end;
    line-height: 144px;
    font-size: 7px;
    font-family: "questrialregular";
    margin: 1px 8px;
  }
  .contact {
    background-color: #3f3d56;
    padding: 10px 21px;
    border-radius: 20px;
    color: white;
  }

  .spotlight h1 {
    font-size: 31px;
    font-family: "montserratbold";
    color: #363636;
  }
  .spotlight p {
    font-size: 12px;
    margin: 30px 9px;
    font-family: "questrialregular";
    visibility: visible;
    animation-duration: 1.5s;
    animation-name: slideInRight;
    color: black;
  }
  .right img {
    width: 378%;
    margin-top: -181%;
    margin-left: -438px;
  }

  .img2 {
    width: 22%;
    margin-top: 33px;
  }
  .img1 {
    width: 18%;
    margin-bottom: 5px;
  }
  .icon1 a img {
    width: 26px;
    border-radius: 24px;
    margin: 148px -21px;
    background-color: #38364d;
    color: #fff;
    padding: 6px;
  }
  .right1 {
    margin: 0;
  }

  .heading1 {
    font-size: 33px;
    color: black;
    font-family: "montserratbold";
  }
  .detail {
    margin: 0;
    padding: 0;
  }
  .room {
    text-align: center;
    font-size: 37px;
    font-family: "montserratbold";
    color: #000000;
    margin-left: 6%;
    visibility: visible;
    animation-duration: 1.5s;
    animation-name: slideInLeft;
    padding: 44px;
  }
  .backend {
    display: flex;
    padding: 110px 44px;
  }
  .contact1 {
    width: 108%;
    padding-top: 14px;
  }

  .parent_2 {
    font-size: 13px;
    font-family: "questrialregular";
    visibility: visible;
    animation-duration: 1.5s;
    animation-name: slideInRight;
    margin-top: 12px;
  }
  .heading {
    display: flex;
  }
  .icon1 {
    width: 3%;
    margin: -93px 34px;
  }
  .bottom {
    background: #38364d;
    text-align: center;
    color: #fff;
  }
  .detail p {
    text-align: center;
  }
  .about {
    margin: 0;
    padding: 0;
  }
  .user {
    margin: 0;
  }
}
